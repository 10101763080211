<script lang="ts">
import TruckIcon from "~/components/icons/TruckIcon.vue";
import DatabaseIcon from "~/components/icons/DatabaseIcon.vue";
import BoxIcon from "~/components/icons/BoxIcon.vue";
import PointIcon from "~/components/icons/PointIcon.vue";

const icons = [
  TruckIcon,
  DatabaseIcon,
  BoxIcon,
  PointIcon
]

</script>

<template>
  <div class="grey-bc">
    <div class="container row">
      <div class="sales-arguments row space-between full-width">
        <SaleArgument :icon="icons[i - 1]"
                      :text="$t(`sales_arguments.${i - 1}.text`)"
                      :title="$t(`sales_arguments.${i - 1}.title`)" v-for="i in 4"/>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/scss/mixins.scss";
@import "@/assets/scss/variables.scss";

.sales-arguments {
  min-height: 120px;
  justify-content: space-between;
  height: 120px;
  border-bottom: 1px solid $dark-grey;

  @include smd {
    height: 336px;
    flex-direction: column;
    gap: 40px;
    justify-content: flex-start;
    align-items: flex-start;
    padding-top: 28px;
    padding-bottom: 28px;
  }
}

</style>
<script setup lang="ts">
import Image from "~/components/atoms/Image.vue";
import Heading from "~/components/atoms/Heading.vue";
import Text from "~/components/atoms/Text.vue";
import SaleArgument from "~/components/molecules/SaleArgument.vue";
</script>
