<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 48 48" fill="none">
    <path
        d="M30.3296 25.5824C38.1952 25.5824 44.5714 22.7485 44.5714 19.2528C44.5714 15.757 38.1952 12.9231 30.3296 12.9231C22.4641 12.9231 16.0879 15.757 16.0879 19.2528C16.0879 22.7485 22.4641 25.5824 30.3296 25.5824Z"
        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M16.0879 19.2527V38.2417C16.0879 41.723 22.4176 44.5714 30.3296 44.5714C38.2417 44.5714 44.5714 41.723 44.5714 38.2417V19.2527"
        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path
        d="M44.5706 28.7472C44.5706 32.2285 38.2409 35.0769 30.3288 35.0769C22.4167 35.0769 16.0871 32.2285 16.0871 28.7472M30.0123 6.59337C26.3142 4.31113 22.0095 3.20736 17.6695 3.42853C9.78905 3.42853 3.42773 6.27688 3.42773 9.7582C3.42773 11.6255 5.26334 13.3028 8.17499 14.5055"
        stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.17499 33.4944C5.26334 32.2918 3.42773 30.6144 3.42773 28.7472V9.75818"
          stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M8.17499 23.9999C5.26334 22.7973 3.42773 21.1199 3.42773 19.2527" stroke-width="2"
          stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'DatabaseIcon'
}
</script>
