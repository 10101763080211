<script setup lang="ts">
import Heading from "~/components/atoms/Heading.vue";
import Text from "~/components/atoms/Text.vue";
import ExpandIcon from "~/components/icons/ExpandIcon.vue";

const props = defineProps({
  icon: {
    required: true
  },
  title: {
    required: true
  },
  text: {
    required: true
  },
  largeIcons: {
    default: true
  },
  link: {
    default: ""
  }
})

const component = computed(() => {
  if (props.link) return defineNuxtLink({})
  return 'div'
})

</script>

<template>
  <component :is="component" :to="link ? link : undefined" class="row argument">
    <div class="no-flex">
      <component :class="{large:largeIcons}" :is="icon"/>
    </div>
    <div class="no-flex">
      <Heading tag="h3">{{ title }}</Heading>
      <Text>{{ text }}</Text>
    </div>

    <ExpandIcon class="expand" v-if="link"/>
  </component>
</template>

<style scoped lang="scss">
@import "@/assets/scss/mixins.scss";
@import "@/assets/scss/variables.scss";

a {
  display: flex;
  width: 100%;
}

h3 {
  font-weight: 500;
}

p {
  font-size: 12px;
}

.expand {
  margin-left: auto;
}

.argument {
  gap: 15px;
}

svg {
  stroke: $black;
  width: 28px;
  height: 28px;
  stroke-width: 1px;

  &.large {
    width: 32px;
    height: 32px;
    stroke-width: 0.5px;

    @include smd {
      width: 38px;
      height: 38px;
    }
  }
}
</style>
